<template>
  <section class="content">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card card-primary">
          <!-- form start -->
          <form role="form" @submit="updateForm">
            <div class="card-body">
              <div class="row">
                <input type="hidden" name="id" id="id" v-model="formItem.id" />
                <div class="col-md-6 form-group">
                  <label class="control-label">NAMA PENERIMA</label>
                  <input
                    id="destName"
                    class="form-control"
                    v-model="formItem.destName"
                    type="text"
                    required="required"
                    name="destName[]"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">TELEPON</label>
                  <input
                    id="destTel"
                    pattern="[0-9]{7,}"
                    formnovalidate
                    class="form-control"
                    v-model="formItem.destTel"
                    type="number"
                    required="required"
                    name="destTel[]"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KOTA / KECAMATAN</label>
                  <v-select
                    label="name"
                    name="addr_id[]"
                    :filterable="false"
                    :options="addrOptions"
                    v-model="formItem.addr_id"
                    :reduce="(opt) => opt.id"
                    @search="onSearch"
                    @input="setSelected"
                  >
                    <template slot="no-options"> Ketik alamat... </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.lokasi }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.lokasi }}
                      </div>
                    </template>
                  </v-select>
                </div>

                <div class="col-md-6 form-group">
                  <label class="control-label">ALAMAT</label>
                  <textarea
                    class="form-control"
                    v-model="formItem.addr_detail"
                    required="required"
                    name="addr_detail[]"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">ISI PAKET</label>
                  <input
                    id="item_name"
                    class="form-control"
                    v-model="formItem.item_name"
                    type="text"
                    required="required"
                    name="item_name[]"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">BERAT (Kg)</label>
                  <input
                    @change="onChangeOngkir($event)"
                    class="form-control"
                    v-model="formItem.weight"
                    type="number"
                    min="0.5"
                    step="0.1"
                    required="required"
                    placeholder="Bisa desimal"
                    name="weight[]"
                  />
                </div>

                <div class="col-md-12 form-group mb-1" v-if="false">
                  <label class="control-label">DIMENSI PAKET</label>
                </div>
                <div class="input-group col-sm-4" v-if="false">
                  <input
                    class="form-control"
                    v-model="formItem.length"
                    type="number"
                    min="1"
                    required="required"
                    name="length[]"
                    placeholder="Panjang"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="length">cm</span>
                  </div>
                </div>

                <div class="input-group col-sm-4" v-if="false">
                  <input
                    class="form-control"
                    v-model="formItem.width"
                    type="number"
                    min="1"
                    required="required"
                    name="width[]"
                    placeholder="Lebar"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="width">cm</span>
                  </div>
                </div>

                <div class="input-group col-sm-4" v-if="false">
                  <input
                    class="form-control"
                    v-model="formItem.height"
                    type="number"
                    min="1"
                    required="required"
                    name="height[]"
                    placeholder="Tinggi"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="height">cm</span>
                  </div>
                </div>
                <div class="col-md-2 form-group mt-3">
                  <label class="control-label">STATUS</label>
                  <div>
                    <toggle-button
                      @change="onToggleChange($event)"
                      :labels="{ checked: 'COD', unchecked: 'NON COD' }"
                      :width="80"
                      :sync="true"
                      :value="Boolean(formItem.codStatus)"
                      v-model="formItem.codStatus"
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group mt-3">
                  <label class="control-label">{{
                    formItem.codStatus ? "Nilai COD" : "Nilai Paket"
                  }}</label>
                  <currency-input
                    class="form-control"
                    currency="IDR"
                    @change="hitungOngkir($event)"
                    v-model="formItem.codValue"
                    v-bind:class="{ 'is-invalid': codValueErr }"
                    :precision="0"
                    name="codValue[]"
                  />
                  <div
                    class="invalid-feedback"
                    max="2500000"
                    id="feedback-1"
                    v-if="errors[0]"
                  >
                    <h6>
                      {{ errors[0].message }}
                    </h6>
                  </div>
                </div>
                <div class="form-group col-md-3 mt-3">
                  <label class="control-label">STATUS ASURANSI</label>
                  <div>
                    <div
                      class="icheck-material-orange icheck-inline form-check"
                    >
                      <input
                        class="form-check-input"
                        id="insurance_type"
                        v-model="formItem.insurance_type"
                        type="checkbox"
                        name="insurance_type[]"
                        @change="hitungOngkir($event)"
                      />
                      <label class="form-check-label" for="insurance_type">
                        Asuransi
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group mb-3">
                  <label class="control-label">CATATAN</label>
                  <input
                    id="note"
                    class="form-control"
                    v-model="formItem.note"
                    type="text"
                    name="note[]"
                  />
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    ONGKIR Standar:
                    <input
                      type="hidden"
                      name="nilai_ongkir[]"
                      id="nilai_ongkir"
                      v-model="formItem.nilai_ongkir"
                    /><strong>
                      {{
                        formItem.nilai_ongkir
                          ? formatMoney(formItem.nilai_ongkir)
                          : "-"
                      }}</strong
                    >
                  </div>
                  <div>
                    Estimasi ONGKIR dengan BOSCOD:
                    <strong>{{
                      formItem.nilai_ongkir
                        ? formatMoney(formItem.nilai_ongkir - formItem.disc)
                        : "-"
                    }}</strong>
                  </div>
                  <div v-if="formItem.codStatus">
                    Estimasi jumlah yang akan diterima :
                    <strong>{{
                      formItem.nilai_ongkir && formItem.codValue
                        ? formatMoney(formItem.totalReceived)
                        : "-"
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div v-if="formItem.no_resi != ''">
                <div class="box-tools card-tools toolbar mr-2"></div>
              </div>
              <button
                v-if="!formItem.no_resi"
                type="submit"
                id="submit"
                class="btn btn-success mr-1"
                :disabled="submitting1"
              >
                <i class="fas fa-check"></i> Submit Pesanan
                <span
                  v-if="submitting1"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                v-if="!formItem.no_resi"
                type="submit"
                id="draft"
                class="btn btn-primary mr-1"
                :disabled="submitting2"
              >
                Simpan Draft2
                <span
                  v-if="submitting2"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <router-link
                :to="{ path: '/order/edit/' + formItem.group_id }"
                class="btn btn-secondary"
              >
                Kembali
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import "icheck-material";

Vue.use(ToggleButton);

export default {
  data() {
    return {
      options: {
        format: "DD/MM/YYYY HH:mm",
      },
      errors: [],
      shouldDisable: false,
      codValueErr: false,
      destTelErr: false,
      totalCourier: [],
      addrOptions: [],
      loading: false,
      courierBranchOption: [],
      roles: "",
      groups: [],
      show: false,
      method: "PUT",
      formItem: {
        //order
        id: "",
        pickup_time: moment().add(4, "h").toDate(),
        group_id: "",
        courier_id: "",
        sender_name: "",
        sender_addr_id: "",
        sender_addr_code: "",
        sender_address: "",
        sender_postcode: "",
        sender_phone: "",
        receiver_name: "",
        receiver_phone: "",
        receiver_addr_id: "",
        receiver_addr_code: "",
        receiver_address: "",
        destName: "",
        destTel: "",
        destCity: "",
        destCode: "",
        order_code: "",
        addr_id: "",
        addr_detail: "",
        item_name: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        note: "",
        nilai_ongkir: 0,
        insurance_type: "",
        pickupend_on: moment().add(2, "d").add(5.5, "h").toDate(),
        codValue: 0,
        codStatus: true,
        submit: "",
        service_code: "",
      },
      og_status: "",
      ongkir: 0,
      ongkirNormal: 0,
      ongkir_satuan: 0,
      submitting1: false,
      submitting2: false,
      sender: {},
    };
  },
  created: function () {
    // alert(this.$route.params.id);
    if (this.$route.params.id) {
      this.loadOrder(this.$route.params.id);
      // this.loadAddress();
    } else {
    }

    // window.onbeforeunload = this.beforeGo;

    //get data courier for dropdown select
    authFetch("/master/courier/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.totalCourier = js.data;
        // this.loadCourierBranch(js.data.courier_id);
      });
  },
  methods: {
    formatMoney(val) {
      return formatCurrency(val);
    },
    onToggleChange(event) {
      // added event as second arg
      this.formItem.codStatus = event.value;
      this.hitungOngkir();
      console.log("cod status:", this.formItem.codStatus);
    },
    setSelected(value) {
      const self = this;
      self.formItem.addr_id = value;
      let s_id = self.formItem.sender_addr_id;
      let r_id = self.formItem.addr_id;
      self.loadOngkir(s_id, r_id);
    },
    loadAddress(id) {
      let route;
      if (id) {
        route = "/order/address/" + id;
      } else {
        route = "/order/address/0";
      }
      authFetch(route).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.addrOptions.push({
              id: json.data.addr_id,
              lokasi: json.data.kota + " / " + json.data.kecamatan,
            });
          }
        });
      });
    },
    hitungOngkir: function () {
      this.hitungAsuransi();

      console.log("hitung ongkir");
      if (this.formItem.codStatus) {
        var codFee = (this.formItem.codValue * this.formItem.feeCod) / 100;
        this.formItem.nilai_ongkir =
          this.formItem.ongkir_dasar +
          (codFee + (codFee * this.formItem.ppnCod) / 100);
        this.formItem.totalReceived =
          this.formItem.codValue -
          (this.formItem.nilai_ongkir - this.formItem.disc) -
          this.formItem.insurance_fee;
      } else {
        this.formItem.nilai_ongkir = this.formItem.ongkir_dasar;
        this.formItem.totalReceived = -(
          this.formItem.nilai_ongkir -
          this.formItem.disc +
          this.formItem.insurance_fee
        );
      }
      console.log("total received", this.formItem, this.formItem.totalReceived);
    },
    hitungAsuransi: function () {
      var itemVal = this.formItem.codValue;
      var insFee = 0;
      console.log("hitung asuransi", this.formItem);
      if (this.formItem.insurance_type) {
        if (itemVal > this.formItem.ins_min) {
          if (this.formItem.ins_fee) insFee = this.formItem.ins_fee;
          else if (this.formItem.ins_percent)
            insFee = (this.formItem.ins_percent * itemVal) / 100;
        }
      }
      this.formItem.insurance_fee = insFee;
      console.log(insFee);
    },
    loadOngkir(s_id, r_id) {
      const self = this;
      let courier_id = self.formItem.courier_id;
      let route;
      var weight = self.formItem.weight;
      if (!weight) weight = 1;
      if (s_id) {
        route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}`;
      } else {
        route = "/order/nilai_ongkir/854/887";
      }
      authFetch(route).then((res) => {
        res.json().then((json) => {
          self.insurance = {
            percent: json.ins,
            fee: json.insfee,
            min: json.insmin,
          };
          if (json.success) {
            let price = json.price == "" ? 0 : json.price;
            // let reverse = price.toString().split("").reverse().join(""),
            //   ribuan = reverse.match(/\d{1,3}/g);
            // ribuan = ribuan.join(".").split("").reverse().join("");

            self.ongkir_satuan = price;
            self.formItem.ongkir_dasar = price * 1;
            self.formItem.feeCod = json.feecod;
            self.formItem.ppnCod = json.ppn;
            self.formItem.disc = json.disc;
            self.formItem.ins_fee = json.insfee;
            self.formItem.ins_percent = json.ins;
            self.formItem.ins_min = json.insmin;
            this.hitungOngkir();
          } else {
            self.formItem.nilai_ongkir = 0;
            self.formItem.addr_id = 0;
            Swal.fire("Proses gagal", `${json.desc}`, "error");
          }
        });
      });
    },
    onChangeOngkir(event) {
      // console.log(event.target.value);
      const self = this;
      self.formItem.weight = event.target.value;
      let ongkir1 = self.ongkir_satuan;
      let nilaiOngkir = ongkir1 * Math.ceil(self.formItem.weight);

      let s_id = self.sender.addr_id;
      let r_id = self.formItem.addr_id;
      self.loadOngkir(s_id, r_id);
      //self.formItem.nilai_ongkir = nilaiOngkir;
      //console.log(self.formItem.nilai_ongkir);
    },
    loadOrder(id) {
      authFetch("/order/item/" + id).then((res) => {
        res.json().then((json) => {
          this.formItem = json;
          this.addrOptions.push({
            id: json.addr_id,
            lokasi: json.destCity + " / " + json.kecamatan,
          });
          this.og_status = json.og_status;
        });
      });
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        //this.search(loading, search, this);
        var vm = this;
        authFetch(
          `/order/wilayah?q=${escape(search)}` +
            "&cid=" +
            this.formItem.courier_id
        ).then((res) => {
          res.json().then((json) => (vm.addrOptions = json.items));
          loading(false);
        });
      }
    },
    updateForm: function (ev) {
      const self = this;

      self.formItem.submit = ev.submitter.id;
      console.log("return : ", self.formItem.submit);
      self.formItem.id = self.$route.params.id;

      var setmethod = self.formItem.id ? "PUT" : "POST";

      var allData = { item: self.formItem };

      if (self.formItem.submit == "draft") {
        this.submitting2 = true;
        var urlSubmit = "/order/item";
        if (self.method == "PUT") urlSubmit = "/order/item/" + self.formItem.id;

        authFetch(urlSubmit, {
          method: setmethod,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(allData),
        })
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            this.errors = [];
            if (!js.success) {
              this.submitting2 = false;
              Swal.fire("Proses gagal", `${js.msg}`, "error");

              console.log(js.details);

              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }

              return;
            }
            this.$router.push("/order/edit/" + self.formItem.group_id);
          });
      } else if (self.formItem.submit == "submit") {
        Swal.fire({
          title: "Submit Pesanan?",
          showCancelButton: true,
          confirmButtonText: `Submit`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitting1 = true;
            var url = "/order/item";
            if (self.method == "PUT")
              url = "/order/item/" + this.$route.params.id;
            authFetch(url, {
              method: setmethod,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(allData),
            })
              .then((res) => {
                this.submitting1 = true;
                return res.json();
              })
              .then((js) => {
                let noOrder = "";
                let msg = "";
                let type = "";
                if (js.success == true) {
                  this.submitting1 = false;
                  for (let i = 0; i < js.data.length; i++) {
                    if (js.data[i].status == true) {
                      noOrder +=
                        "No. Order: " +
                        js.data[i].no_order +
                        "<br>" +
                        "No. Resi: " +
                        js.data[i].no_resi +
                        "<br>";
                      msg = "Proses Submit Berhasil";
                      type = "success";
                    } else if (js.data[i].status == false) {
                      noOrder +=
                        "No. Order Yang Gagal: " + js.data[i].no_order + "<br>";
                      msg = "Proses Gagal";
                      type = "error";
                    }
                  }
                  Swal.fire(
                    msg,
                    `Total: ${js.total} <br>
                  ${noOrder}`,
                    type
                  );
                  this.$router.push("/order/edit/" + self.formItem.group_id);
                } else if (js.success == false) {
                  this.submitting1 = false;
                  if (js.msg) {
                    Swal.fire("Proses gagal", `${js.msg}`, "error");
                  } else {
                    for (let i = 0; i < js.output.length; i++) {
                      Swal.fire("Proses gagal", `${js.output[i].msg}`, "error");
                    }
                  }
                  this.loadOrder(this.$route.params.id);
                }
              });
          }
        });
      }
      ev.preventDefault();
    },
  },
  components: {
    datepicker,
    timepicker,
    vSelect,
    maskedInput,
    CurrencyInput,
    datePicker,
  },
  mounted() {},
};
</script>
<style scoped>
</style>